<template>
  <div>
    <v-form ref="validate" @submit.prevent="onSubmit()">
      <div class="d-flex justify-end mb-8">
        <v-btn large color="primary" @click="addForm()" class="mr-6"
          >เพิ่ม</v-btn
        >
        <v-btn large dark color="green" type="submit">บันทึก</v-btn>
      </div>
      <v-row v-for="(item, index) in form" :key="index">
        <v-col cols="4">
          <v-text-field
            v-model="form[index].point"
            outlined
            class="my-0 py-0"
            type="number"
            label="จำนวนแต้ม"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="form[index].money"
            outlined
            class="my-0 py-0"
            type="number"
            label="แทนส่วนลด"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            x-large
            class="mr-6"
            dark
            color="red"
            @click="removeForm(index)"
            >ลบ</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {
          point: 0,
          money: 0,
        },
      ],
    },
  },
  data() {
    return {
      form: this.items,
    };
  },
  mounted() {},
  computed: {
    empytyRules() {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
  },
  methods: {
    onSubmit() {
      const validate = this.$refs.validate.validate();
      if (validate) {
        this.$emit("input", this.form);
      }
    },
    addForm() {
      this.form.push({
        money: "",
        point: "",
      });
    },
    removeForm(index) {
      if (this.form.length > 1) {
        this.form.splice(index, 1);
      }
    },
  },
};
</script>
