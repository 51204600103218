<template>
  <div>
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <h2 class="text-secondary header">การตั้งค่า</h2>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-6">
      <div class="mb-4 mt-8">
        <v-select
          class="mr-4"
          label="สาขา"
          outlined
          :items="branchItems"
          item-text="text"
          item-value="value"
          v-model="branch"
          @change="getAllSetting()"
        ></v-select>
      </div>
      <v-card>
        <v-card-title> ตั้งค่าอายุ</v-card-title>

        <v-container>
          <AgeForm
            v-if="reloaded"
            :items="ageRange"
            @input="onSaveAgeRange($event)"
          />
        </v-container>
      </v-card>
      <v-card class="mt-10 mb-10">
        <v-card-title> ตั้งค่าแต้มที่ได้รับ</v-card-title>

        <v-container>
          <PointToMoneyForm
            v-if="reloaded"
            :items="moneyToPoint"
            @input="onSaveMoney($event)"
          />
        </v-container>
      </v-card>
      <v-card class="mt-10 mb-10">
        <v-card-title> ตั้งค่าแต้มแทนส่วนลด </v-card-title>
        <v-container>
          <MoneyToPointForm
            v-if="reloaded"
            :items="pointToMoney"
            @input="onSavePoint($event)"
          />
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AgeForm from "@/components/Setting/AgeForm.vue";
import PointToMoneyForm from "@/components/Point/PointToMoneyForm.vue";
import MoneyToPointForm from "@/components/Point/MoneyToPointForm.vue";
import axios from "../../utils/axios.js";
export default {
  components: {
    AgeForm,
    MoneyToPointForm,
    PointToMoneyForm,
  },
  data() {
    return {
      branch: "",
      moneyToPoint: [],
      pointToMoney: [],
      ageRange: [],
      reloaded: false,
    };
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    this.getAllSetting();
  },
  methods: {
    async getAllSetting() {
      try {
        this.reloaded = false;
        const {
          data: { data },
        } = await axios.get(`/setting?branch=${this.branch}`);
        const result = data[0];
        if (result) {
          this.moneyToPoint = result.moneyToPoint;
          this.pointToMoney = result.pointToMoney;
          this.ageRange = result.ageRange;
        } else {
          this.moneyToPoint = [
            {
              money: 0,
              point: 0,
            },
          ];
          this.pointToMoney = [
            {
              money: 0,
              point: 0,
            },
          ];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.reloaded = true;
      }
    },
    async onSavePoint(event) {
      try {
        const payload = {
          pointToMoney: [...event],
        };
        await axios.patch(`/setting/${this.branch}/branch`, payload);
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      }
    },
    async onSaveMoney(event) {
      try {
        const payload = {
          moneyToPoint: [...event],
        };
        await axios.patch(`/setting/${this.branch}/branch`, payload);
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      }
    },
    async onSaveAgeRange(event) {
      try {
        const payload = {
          ageRange: [...event],
        };
        await axios.patch(`/setting/${this.branch}/branch`, payload);
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>
