<template>
  <div>
    <v-form ref="validate" @submit.prevent="onSubmit()">
      <div class="d-flex justify-end mb-8">
        <v-btn large dark color="green" type="submit">บันทึก</v-btn>
      </div>
      <v-row v-for="(item, index) in form" :key="index">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form[index].text"
            outlined
            disabled
            class="my-0 py-0"
            label="ประเภท"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form[index].age"
            outlined
            disabled
            class="my-0 py-0"
            @keypress="$keypressNumberOnly()"
            :label="
              form.length - 1 === index
                ? 'ช่วงอายุ (มากกว่าเท่ากับ)'
                : 'ช่วงอายุ (น้อยกว่าเท่ากับ)'
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form[index].price"
            outlined
            class="my-0 py-0"
            type="number"
            label="ราคา"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        {
          point: 0,
          money: 0,
        },
      ],
    },
  },
  data() {
    return {
      form: this.items,
    };
  },
  mounted() {},
  computed: {
    empytyRules() {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
    numberRule: () => {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
  },
  methods: {
    onSubmit() {
      const validate = this.$refs.validate.validate();
      if (validate) {
        this.$emit("input", this.form);
      }
    },
    addForm() {
      this.form.push({
        money: "",
        point: "",
      });
    },
    removeForm(index) {
      if (this.form.length > 1) {
        this.form.splice(index, 1);
      }
    },
  },
};
</script>
